jQuery(function ($) {

    function quizcta_slide(slide) {
        let quizcta = $('#quizcta');
        let totalSlides = $(quizcta).find('.quizcta--slider-item').length;
        let currentSlide = parseInt($(quizcta).data('current'));

        let requestedSlide = currentSlide+slide;
        console.log(totalSlides);
        console.log(requestedSlide);

        if( requestedSlide > totalSlides || requestedSlide < 1 ) {
            return;
        }

        $(quizcta).find('.quizcta--slider-item').hide();
        $(quizcta).find('.quizcta--slider-item:nth-child('+requestedSlide+')').show();
        $(quizcta).find('.quizcta--content-highlights-item').hide();
        $(quizcta).find('.quizcta--content-highlights-item:nth-child('+requestedSlide+')').show();

        $(quizcta).data('current', requestedSlide);
        
        if( requestedSlide + 1 > totalSlides ) {
            $('#quizcta--slider-arrow-right').hide();
        }
        else {
            $('#quizcta--slider-arrow-right').show();
        }

        if( requestedSlide - 1 < 1 ) {
            $('#quizcta--slider-arrow-left').hide();
        }
        else {
            $('#quizcta--slider-arrow-left').show();
        }
    }

    $('.quizcta--content-highlights-item:first-child').show();
    $('.quizcta--slider-item:first-child').show();
    $('#quizcta--slider-arrow-left').hide();

    $('#quizcta--slider-arrow-right').click(function() {
        quizcta_slide(1);
    });
    $('#quizcta--slider-arrow-left').click(function() {
        quizcta_slide(-1);
    });

});